import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProtectedRoute() {
    const {isAuthenticated} = useSelector(state => state.auth)
    // Authentication status should ideally be managed globally (e.g., with context or Redux)

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
}

export default ProtectedRoute;
