export const getTokens = () => {
    const tokens = localStorage.getItem('tokens');
    return tokens ? JSON.parse(tokens) : null;
  };
  
  export const getAccessToken = () => {
    const tokens = getTokens();
    return tokens ? tokens.response.access : null;
  };
  
  export const setTokens = (tokens) => {
    localStorage.setItem('tokens', JSON.stringify(tokens));
  };
  
  export const clearTokens = () => {
    localStorage.removeItem('tokens');
  };
  