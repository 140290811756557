import { apiSlice } from './apiSlice';

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBasicData: builder.mutation({
      query: (data) => ({
        url: '/dashboard-basic-data/',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetBasicDataMutation
} = dashboardApiSlice;
