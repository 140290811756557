import { apiSlice } from './apiSlice';
import { setCredentials, logout } from './authSlice';

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updatePincode: builder.mutation({
      query: (data) => ({
        url: '/update-pincode/',
        method: 'POST',
        body: data,
      }),
  }),
  updateFee: builder.mutation({
    query: (data) => ({
      url: '/update-fee/',
      method: 'POST',
      body: data,
    }),
}),
})
});
export const {
 useUpdatePincodeMutation,
 useUpdateFeeMutation,
} = settingsApiSlice;
