import { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Donors from "./scenes/donors";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Login from "./scenes/login";
import Register from "./scenes/register";
import ProtectedRoute from "./components/ProtectedRoute";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Campaigns from "./scenes/campaigns";
import Devices from "./scenes/devices";
import Reports from "./scenes/reports/indes";
import UserAccounts from "./scenes/userAccounts/indes";
import { setLoading } from "./state/slices/appLoadingSlice";
import { usePrefetchReports } from "./customHooks/GeneralMethods";
import loading_img from './images/olivepod.gif'

import { Bars, Puff } from "react-loader-spinner";
import Settings from "./scenes/settings";
import WebCampaign from "./scenes/webCampaign";


function App() {
    const dispatch =  useDispatch();

    const  {fetchReports } = usePrefetchReports();
    
    const appLoading = useSelector((state)=> state.appLoading.is_loading)
    const { isAuthenticated } = useSelector((state) => state.auth);

    useEffect(() => {
      if (isAuthenticated && location.pathname.split('/')[1] !== "web-campaign" ){
        appLoad();
        console.log(appLoading)

      }

    }, []);

    const appLoad = async () =>{
      dispatch(setLoading(true));
      await fetchReports();
      dispatch(setLoading(false));
    }



  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  const location = useLocation(); // This hook gives you access to the location object
  const showSidebarAndTopbar =
    isAuthenticated && location.pathname !== "/login" && location.pathname !== "/register" && location.pathname.split('/')[1] !== "web-campaign" ;

    // console.log(location.pathname.split('/'), ' &&&&&&&&&&&&&&&& testing web camp ')

const [sidebarcollapsed, setSidebarcollapsed] = useState();

  const isSidebarCollapsed = (iscollapsed) =>{
    setSidebarcollapsed(iscollapsed)
  }


  if (appLoading){
    return (
      <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', }} >

      <Puff color="#00f2d5" height={80} width={80} />
      </div>
    )
  } 

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {showSidebarAndTopbar && (
            <div className="sidebar">
              <Sidebar onCollapse={isSidebarCollapsed} isSidebar={isSidebar} />
            </div>
          )}

          <main className={`content ${!showSidebarAndTopbar ? "full-width" : ""}`}
            style={{
              ...(showSidebarAndTopbar && {
                marginLeft: sidebarcollapsed ? "80px" : "270px",
                transition: "ease-in-out 0.2s",
              }),
            }}
          
             >
        
            {showSidebarAndTopbar && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />

              {/* Protecting routes using ProtectedRoute */}
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/team" element={<Team />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/campaigns" element={ <Campaigns/> } />
                <Route path="/devices" element={ <Devices/> } />
                <Route path="/donors" element={<Donors />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/user-accounts" element={<UserAccounts />} />
                <Route path="/settings" element={<Settings />} />

                <Route path="/form" element={<Form />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/geography" element={<Geography />} />
              </Route>
              <Route path="/web-campaign/:id" element={<WebCampaign />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
