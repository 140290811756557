import { apiSlice } from './apiSlice';

export const userAccountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserAccounts: builder.mutation({
      query: (data) => ({
        url: '/get-user-accounts/',
        method: 'POST',
        // body: data
      }),
    }),
    approveUser: builder.mutation({
      query: (data) => ({
        url: '/approve-user/',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetUserAccountsMutation,
  useApproveUserMutation,
} = userAccountApiSlice;
