import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataInvoices } from "../../data/mockData";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useGetDevicesMutation } from "../../state/slices/deviceApiSlice";
import { setDevice } from "../../state/slices/deviceSlice";



const Devices = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();

  const deviceData = useSelector((state) => state.device.devices);
  const [getDevices, {data, error, isLoading}] = useGetDevicesMutation()


  useEffect(() => {
    fetchDevices();
    
  }, []);

  const fetchDevices = async () =>{
    try {
      const devices = await getDevices();
      console.log(devices)
      dispatch(setDevice(devices));
    } catch (error) {
      console.log(error)
    }
  }


  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "device_id",
      headerName: "Device ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      flex: 1,
      // cellClassName: "name-column--cell",
    },
    {
      field: "device_type",
      headerName: "Device Type",
      flex: 1,
      // cellClassName: "name-column--cell",
    },
    {
      field: "country_location",
      headerName: "Country",
      flex: 1,
      // cellClassName: "name-column--cell",
    },

   
  ];

  return (
    <Box m="20px">
      <Header title="Devices" subtitle="List of Devices" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #dddddd",
            fontSize: 14

          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            fontSize: 14
            
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: 'white',
            fontSize: 15
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
        // checkboxSelection 
        rows={deviceData} columns={columns} 
        components={{ Toolbar: GridToolbar }}
        loading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default Devices;
