import { apiSlice } from './apiSlice';

export const deviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDevices: builder.mutation({
      query: (data) => ({
        url: '/get-devices/',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetDevicesMutation
} = deviceApiSlice;
