import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataInvoices } from "../../data/mockData";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useGetReportsMutation } from "../../state/slices/reportsApiSlice";
import { setReports } from "../../state/slices/reportsSlice";

const Reports = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();

  const reportData = useSelector((state) => state.report.reports);
  const [getReports, { data, error, isLoading }] = useGetReportsMutation();
  console.log(reportData, ' report data')
  useEffect(() => {
    // fetchReports();
  }, []);

  const fetchReports = async () => {
    try {
      const reports = await getReports({days:365});
      console.log(reports);
      dispatch(setReports(reports));
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "product_summary",
      headerName: "Campaign",
      flex: 1,
      // cellClassName: "name-column--cell",
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      // cellClassName: "name-column--cell",
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "transaction_code",
      headerName: "Transaction Code",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "entry_mode",
      headerName: "Entry Mode",
      flex: 1,
    },
    {
      field: "card_type",
      headerName: "Card Type",
      flex: 1,
    },
    {
      field: "timestamp",
      headerName: "Time Stamp",
      flex: 1,
    },
    {
        field: "user",
        headerName: "Merchant User",
        flex: 1,
      },
  ];

  return (
    <Box m="20px">
      <Header title="Reports" subtitle="" />

      <Box boxShadow={2} padding={2}>
        <Typography variant="h5" >

        Download Report:
        </Typography>
        
      </Box>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #dddddd",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: "white",
            fontSize: 14,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {reportData && 
        <DataGrid
        // checkboxSelection
        loading={isLoading}
        rows={reportData}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        />
    }
      </Box>
    </Box>
  );
};

export default Reports;
