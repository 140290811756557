import {createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = {
    devices: []
}



export const deviceSlice = createSlice({
    name: 'donor',
    initialState,
    reducers: {
        setDevice: (state, action) => {
            state.devices = action.payload.data
            console.log(state.devices, '  devices')
        },
       
    }
})

export const {setDevice} = deviceSlice.actions

export default deviceSlice.reducer