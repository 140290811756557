import { apiSlice } from './apiSlice';

export const campaignApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCampaigns: builder.mutation({
      query: (data) => ({
        url: '/get-campaigns/',
        method: 'POST',
      }),
    }),
    getCampaign: builder.mutation({
      query: (data) => ({
        url: '/get-campaign/',
        method: 'POST',
        body: data
      }),
    }),
    addCampaign: builder.mutation({
      query: (data) => ({
        url: '/create-campaign/',
        method: 'POST',
        body: data,
      }),
    }),
    deleteCampaign: builder.mutation({
      query: (data) => ({
        url: '/delete-campaign/',
        method: 'POST',
        body: data,
      }),
    }),
    setDefaultCampaign: builder.mutation({
      query: (data) => ({
        url: '/set-default-campaign/',
        method: 'POST',
        body: data,
      }),
    }),




  }),
});

export const {
  useGetCampaignsMutation, 
  useAddCampaignMutation,
  useDeleteCampaignMutation,
  useSetDefaultCampaignMutation,
  useGetCampaignMutation,
} = campaignApiSlice;
