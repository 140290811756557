import {createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = {
    user:null

}



export const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload.user_res.user
            console.log(action.payload , 'current user')
        },
       
    }
})

export const {setUser} = user.actions

export default user.reducer