import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function toaster(type='success', msg, position = "top-right") {
    toast(msg, {
        position,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    })
}