// ButtonComponent.jsx

import React from 'react';

const DonationButton = (
  {
    value,
    onclick
  }
) => {
  const buttonStyle = {
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: '#FCFCFD',
    borderRadius: '4px',
    borderWidth: '0',
    boxShadow:
      'rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset',
    boxSizing: 'border-box',
    color: '#36395A',
    cursor: 'pointer',
    display: 'inline-flex',
    fontFamily: '"JetBrains Mono", monospace',
    height: '48px',
    justifyContent: 'center',
    lineHeight: 1,
    overflow: 'hidden',
    paddingLeft: '16px',
    paddingRight: '16px',
    position: 'relative',
    textAlign: 'left',
    textDecoration: 'none',
    transition: 'box-shadow .15s, transform .15s',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    willChange: 'box-shadow, transform',
    fontSize: '28px',
    width: 180,
    height: 80
  };

  const focusStyle = {
    boxShadow:
      '#D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset',
  };

  const hoverStyle = {
    boxShadow:
      'rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset',
    transform: 'translateY(-2px)',
  };

  const activeStyle = {
    boxShadow: '#D6D6E7 0 3px 7px inset',
    transform: 'translateY(2px)',
  };

  return (
    <button
      onClick={onclick}
      className="button-30"
      style={buttonStyle}
      onFocus={(e) => (e.target.style.boxShadow = focusStyle.boxShadow)}
      onBlur={(e) => (e.target.style.boxShadow = buttonStyle.boxShadow)}
      onMouseOver={(e) => (e.target.style.boxShadow = hoverStyle.boxShadow)}
      onMouseOut={(e) => (e.target.style.boxShadow = buttonStyle.boxShadow)}
      onMouseDown={(e) => {
        e.target.style.boxShadow = activeStyle.boxShadow;
        e.target.style.transform = activeStyle.transform;
      }}
      onMouseUp={(e) => {
        e.target.style.boxShadow = buttonStyle.boxShadow;
        e.target.style.transform = buttonStyle.transform;
      }}
    >
      £ {value || "30"} 
    </button>
  );
};

export default DonationButton;
