import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { tokens } from "../../theme";
import { mockDataInvoices } from "../../data/mockData";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { HexColorPicker } from "react-colorful";
import {
  useGetCampaignsMutation,
  useAddCampaignMutation,
  useDeleteCampaignMutation,
  useSetDefaultCampaignMutation,
} from "../../state/slices/campaignApiSlice";

import { setCampaign } from "../../state/slices/campaignSlice";
import "./style.scss";
import { green } from "@mui/material/colors";
import Modal1 from "../../components/Modal1";
import StepForm from "../../components/StepFrom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { SketchPicker } from "react-color";
import { useFormik } from "formik";
import { campaignSchema } from "../../utils/form_schemas";
import toaster from "../../utils/toast";

import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { saveAs } from "file-saver";
import QRCode from "qrcode.react";

import { Link, useLocation } from "react-router-dom";


const Campaigns = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const campaignData = useSelector((state) => state.campaign.campaigns);

  console.log("campaign data", campaignData);

  const [logo, setLogo] = useState(null);
  const [multimedia, setMultimedia] = useState(null);

  const [selectedCampaign, setSelectedCampaign] = useState();

  const [getCampaigns, { data, error, isLoading }] = useGetCampaignsMutation();
  const [addCampaign, { data2, error2, isLoading2 }] = useAddCampaignMutation();
  const [deleteCampaign, { isDeletingCampaign }] = useDeleteCampaignMutation();
  const [setDefaultCampaign] = useSetDefaultCampaignMutation();

  const location = useLocation();


  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    try {
      const campaigns = await getCampaigns();
      dispatch(setCampaign(campaigns));
    } catch (error) {
      console.error(error);
    }
  };

  const [showQrModal, setShowQrModal] = useState(false);
  const [selectedQr, setSelectedQr] = useState(false);

  const qrRef = useRef();

  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector("canvas");
    canvas.toBlob((blob) => {
      saveAs(blob, "campaign-qr-code.png");
    });
  };

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
    },
    {
      field: "campaign_type",
      headerName: "Campaign Type",
      flex: 1,
    },
    {
      field: "template_name",
      headerName: "Template Name",
      flex: 1,
    },
    {
      field: "qr_code",
      headerName: "QR",
      flex: 1,
      renderCell: (params) => {
        const campaignUrl = `${window.location.origin}/web-campaign/${params.row.hash_id}`;
        return (
          <div style={{display: 'flex', gap:10}} >

          <div style={{ display: 'flex', justifyContent: 'center', cursor:'pointer' , alignItems:'center' }}
            onClick={()=> {
              setSelectedQr(campaignUrl)
              setShowQrModal(true);
              // downloadQRCode();
            }}
            >
            <QRCode value={campaignUrl} size={30} />    

          </div>

            <Link  target="_blank" to={`/web-campaign/${params.row.hash_id}`} > url </Link>
            

            </div>

        );
      },
    },
    {
      // field: "entry_mode",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <Tooltip title="Delete" placement="top" arrow co>
            <span
              style={{ color: "#ff6363", cursor: "pointer" }}
              onClick={() => {
                setSelectedCampaign(params.row);
                setIsDeleteCampaignModalOpen(true);
              }}
            >
              <DeleteIcon />
            </span>
          </Tooltip>
          {/* <Tooltip title="Edit" placement="top" arrow>
            <span style={{  cursor: "pointer" }}>
              <EditIcon />
            </span>
          </Tooltip> */}
          {params?.row?.is_default ? (
            <Tooltip title="make it default" placement="top" arrow>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleSetDefaultCampaign(params.row.id)}
              >
                <LockIcon />
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="remove from default" placement="top" arrow>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => handleSetDefaultCampaign(params.row.id)}
              >
                <LockOpenIcon />
              </span>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  const handleSetDefaultCampaign = async (id) => {
    const res = await setDefaultCampaign({ id }).unwrap();
    if (res.status === 200) {
      // toaster("success", res.msg);
      fetchCampaigns();
    } else {
      toaster("error", res.msg);
    }
  };

  const handleDeleteCampaign = async () => {
    console.log(" delte camp callled");
    const res = await deleteCampaign({ id: selectedCampaign.id }).unwrap();
    console.log(res, "this is reponse from server");
    if (res.status === 200) {
      console.info("camp deleted success");
      toaster("success", res.msg);
      closeDeleteCampaignModal();
      fetchCampaigns();
    } else {
      toaster("error", res.msg);
    }
    // setIsDeleteCampaignModalOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [isdeleteCampaignModalOpen, setIsDeleteCampaignModalOpen] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      campaign_type: "single",
      campaign_name: "",
      multiple_campaign_names: ["", ""],
      template_name: "single",
      background_colour: "#fff",
      text_colour: "#000",
      heading: "",
      message: "",
      buttons: [""],
      custom_amount: false,
      transaction_fee: false,
      gift_aid: false,
      subscribe: false,
      custom_thanks: false,
      thanks_message: "",
      logo: "",
      multimedia_file: "",
    },
    validationSchema: campaignSchema,
    onSubmit: async (values) => {
      console.log("on submit called");
      console.log(values);

      const formData = new FormData();
      for (const key in values) {
        formData.append(key, values[key]);
      }
      formData.append("logo", logo);
      formData.append("multimedia_file", multimedia);
      formData.delete("buttons");
      formData.append("buttons", JSON.stringify(formik.values.buttons));

      console.log(formData.get("buttons"), " theses a re buttons");

      try {
        const res = await addCampaign(formData);
        console.log(res, "<------------ this is reponse test");
        if (error) {
          toaster("error", "campaign not created ");
        } else {
          closeModal();
          toaster("success", "Campaign Created");
        }
      } catch (error) {
        toaster("error", "campaign not created ");
      }
    },
  });

  console.log(formik.errors);

  const openModal = () => {
    formik.handleReset();
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);
  const closeDeleteCampaignModal = () => {
    setIsDeleteCampaignModalOpen(false);
    setSelectedCampaign(null);
  };

  const steps = ["Campaign Type", "template_name", "Add Details", "Finishing"];

  const stepContent = [
    {
      label: "Select Campaign Type",
      content: (
        <form onSubmit={formik.handleSubmit}>
          <FormLabel component="legend">Campaign Type</FormLabel>
          <RadioGroup
            row
            aria-label="campaign_type"
            name="campaign_type"
            value={formik.values.campaign_type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value="single"
              control={<Radio />}
              label="Single"
            />
            <FormControlLabel
              value="multiple"
              control={<Radio />}
              label="Multiple"
            />
          </RadioGroup>
          <TextField
            margin="normal"
            required
            fullWidth
            id="campaign_name"
            label="Name your Campaign"
            name="campaign_name"
            autoComplete="campaign_name"
            size="small"
            value={formik.values.campaign_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.campaign_name &&
              Boolean(formik.errors.campaign_name)
            }
            helperText={
              formik.touched.campaign_name && formik.errors.campaign_name
            }
          />
          {formik.values.campaign_type === "multiple" && (
            <div>
              <Typography variant="subtitle1">
                Below names will appear in your report
              </Typography>
              {formik.values.multiple_campaign_names.map((name, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id={`multiple_campaign_names.${index}`}
                    label={`Campaign Name ${index + 2}`}
                    name={`multiple_campaign_names.${index}`}
                    autoComplete={`multiple_campaign_names.${index}`}
                    size="small"
                    value={name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.multiple_campaign_names?.[index] &&
                      Boolean(formik.errors.multiple_campaign_names?.[index])
                    }
                    helperText={
                      formik.touched.multiple_campaign_names?.[index] &&
                      formik.errors.multiple_campaign_names?.[index]
                    }
                  />
                  {formik.values.multiple_campaign_names.length > 2 && (
                    <span
                      style={{ color: "#ff6363", cursor: "pointer" }}
                      onClick={() => {
                        const newNames =
                          formik.values.multiple_campaign_names.filter(
                            (_, i) => i !== index
                          );
                        formik.setFieldValue(
                          "multiple_campaign_names",
                          newNames
                        );
                      }}
                    >
                      <DeleteIcon />
                    </span>
                  )}
                </div>
              ))}
              {formik.values.multiple_campaign_names.length < 5 && (
                <Button
                  onClick={() => {
                    formik.setFieldValue("multiple_campaign_names", [
                      ...formik.values.multiple_campaign_names,
                      "",
                    ]);
                  }}
                >
                  Add Another Campaign Name
                </Button>
              )}
            </div>
          )}
        </form>
      ),
    },
    {
      label: "Select a template_name",
      content: (
        <div>
          <FormLabel component="legend">template_name</FormLabel>
          <RadioGroup
            row
            aria-label="template_name"
            name="template_name"
            value={formik.values.template_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <FormControlLabel
              value="single"
              control={<Radio />}
              label="Single"
            />
            <FormControlLabel value="logo" control={<Radio />} label="Logo" />
            <FormControlLabel
              value="multimedia"
              control={<Radio />}
              label="Multimedia"
            />
          </RadioGroup>
          {formik.values.template_name === "single" && (
            <div>
              <Typography variant="subtitle1">Select a color</Typography>
              <SketchPicker
                color={formik.values.background_colour}
                onChangeComplete={(newColor) =>
                  formik.setFieldValue("background_colour", newColor.hex)
                }
              />
              {/* <HexColorPicker color={formik.values.background_colour} onChange={(newColor) =>
                  formik.setFieldValue("background_colour", newColor.hex)
                } 
                /> */}
            </div>
          )}
          {formik.values.template_name === "logo" && (
            <div>
              <Typography variant="subtitle1">
                Upload a logo and select a color
              </Typography>
              <TextField
                type="file"
                fullWidth
                margin="normal"
                size="small"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  setLogo(event.currentTarget.files[0]);
                  formik.setFieldValue("logo", event.currentTarget.files[0]);
                }}
              />
              <SketchPicker
                color={formik.values.color}
                onChangeComplete={(newColor) =>
                  formik.setFieldValue("color", newColor.hex)
                }
              />
              {/* <HexColorPicker color={formik.values.color} onChange={(newColor) =>
                  formik.setFieldValue("color", newColor.hex)
                } 
                /> */}
            </div>
          )}
          {formik.values.template_name === "multimedia" && (
            <div>
              <Typography variant="subtitle1">Upload multimedia</Typography>
              <TextField
                type="file"
                fullWidth
                margin="normal"
                size="small"
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  setMultimedia(event.currentTarget.files[0]);
                  formik.setFieldValue("multimedia_file", event.currentTarget.files[0]);
                }}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      label: "Add Details",
      content: (
        <div>
          <Typography variant="subtitle1">
            Set colour for your campaign heading and message
          </Typography>
          <SketchPicker
            color={formik.values.text_colour}
            onChangeComplete={(newColor) =>
              formik.setFieldValue("text_colour", newColor.hex)
            }
          />
          {/* <HexColorPicker color={formik.values.text_colour} onChange={(newColor) =>
                  formik.setFieldValue("text_colour", newColor.hex)
                } 
                /> */}
          <TextField
            margin="normal"
            required
            fullWidth
            id="heading"
            label="Heading for your campaign"
            name="heading"
            autoComplete="heading"
            size="small"
            value={formik.values.heading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.heading && Boolean(formik.errors.heading)}
            helperText={formik.touched.heading && formik.errors.heading}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="message"
            label="Message"
            name="message"
            autoComplete="message"
            size="small"
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
          />
          <Typography variant="subtitle1">Amount fields</Typography>
          {formik.values.buttons.map((field, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <TextField
                key={index}
                margin="normal"
                required
                fullWidth
                id={`buttons.${index}`}
                label={`Amount Field ${index + 1}`}
                name={`buttons.${index}`}
                autoComplete={`buttons.${index}`}
                size="small"
                value={field}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.buttons?.[index] &&
                  Boolean(formik.errors.buttons?.[index])
                }
                helperText={
                  formik.touched.buttons?.[index] &&
                  formik.errors.buttons?.[index]
                }
              />
              {formik.values.buttons.length > 1 && (
                <span
                  style={{ color: "#ff6363", cursor: "pointer" }}
                  onClick={() => {
                    const newNames = formik.values.buttons.filter(
                      (_, i) => i !== index
                    );
                    formik.setFieldValue("buttons", newNames);
                  }}
                >
                  <DeleteIcon />
                </span>
              )}
            </div>
          ))}
          {formik.values.buttons.length < 5 && (
            <Button
              onClick={() => {
                formik.setFieldValue("buttons", [...formik.values.buttons, ""]);
              }}
            >
              Add Another Amount Field
            </Button>
          )}
        </div>
      ),
    },
    {
      label: "Finishing Touches",
      content: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1">Additional Settings</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.custom_amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="custom_amount"
                color="primary"
              />
            }
            label="Allow Donor to Enter Amount"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.transaction_fee}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="transaction_fee"
                color="primary"
              />
            }
            label="Ask donor to contribute towards transaction fee"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.gift_aid}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="gift_aid"
                color="primary"
              />
            }
            label="Collect donor gift aid data"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.subscribe}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="subscribe"
                color="primary"
              />
            }
            label="Allow Donor to Subscribe"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.custom_thanks}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="custom_thanks"
                color="primary"
              />
            }
            label="Enter your own thank you message for donors"
          />
          {formik.values.custom_thanks && (
            <TextField
              margin="normal"
              required
              fullWidth
              id="thanks_message"
              label="Custom Thank You Message"
              name="thanks_message"
              autoComplete="thanks_message"
              size="small"
              value={formik.values.thanks_message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.thanks_message &&
                Boolean(formik.errors.thanks_message)
              }
              helperText={
                formik.touched.thanks_message && formik.errors.thanks_message
              }
            />
          )}
        </div>
      ),
    },
  ];

  // const isStepValid = (stepIndex) => {
  //   const errors = Object.keys(formik.errors).filter(
  //     (key) => formik.touched[key]
  //   );
  //   switch (stepIndex) {
  //     case 0:
  //       return (
  //         errors.length === 0 ||
  //         (errors.length === 1 && errors[0] === "multiple_campaign_names")
  //       );
  //     case 1:
  //       return errors.length === 0;
  //     case 2:
  //       return errors.length === 0;
  //     case 3:
  //       return errors.length === 0;
  //     default:
  //       return true;
  //   }
  // };

  const stepFields = [
    ["campaign_type", "campaign_name", "multiple_campaign_names"],
    ["template_name", "background_colour", "text_colour", "logo"],
    ["heading", "message", "buttons"],
    [
      "custom_amount",
      "transaction_fee",
      "gift_aid",
      "subscribe",
      "custom_thanks",
      "thanks_message",
    ],
  ];
  // const isStepValid = (currentStep) => {

  //   const currentStepFields = stepFields[currentStep];

  //   // Filter out the fields that have errors in formik.errors
  //   const stepErrors = currentStepFields?.filter(
  //     (field) => formik.errors[field]
  //   );

  //   console.log(currentStep, ' <-------------- current step');
  //   console.log(stepErrors, ' <-------------- step errors');

  //   // If stepErrors is an empty array, return true (valid step), otherwise return false
  //   return stepErrors?.length === 0;
  // };

  const [disabledNext, setDisabledNext] = useState();

  const handleNext = async () => {
    if (currentStep === steps.length - 1) {
      formik.handleSubmit();
      // Call the onSubmit function if it's the last step
      setCurrentStep(0);
      console.log("last step");
    }

    const currentStepFields = stepFields[currentStep];

    // Trigger formik's validation for the current step fields
    const validationErrors = await formik.validateForm();

    // Check if there are any validation errors for the current step fields
    const hasErrors = currentStepFields.some(
      (field) => validationErrors[field]
    );

    // If no errors, proceed to the next step
    if (!hasErrors && currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else {
      // Mark the fields as touched to show validation errors

      currentStepFields.forEach((field) => {
        formik.setFieldTouched(field, true);
      });
    }
  };

  const handleBack = () => {
    console.log(" back presssed ");
    if (currentStep >= 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <Box m="20px">
      <Modal1 isOpen={isModalOpen} onClose={closeModal} maxWidth="800px">
        <StepForm
          steps={steps}
          stepContent={stepContent}
          currentStep={currentStep}
          onNext={handleNext}
          onBack={handleBack}
          onSubmit={formik.handleSubmit}
          // onSubmit={}
          // disabledNext={disabledNext}

          // isStepValid={isStepValid}
        />
      </Modal1>



      <Modal1
        isOpen={isdeleteCampaignModalOpen}
        onClose={closeDeleteCampaignModal}
      >
        <Typography variant="h4">
          Are you sure you want to delete this campaign?
        </Typography>
        <Box display="flex" gap={2} justifyContent="center" m="20px 0">
          <Button
          // variant="contained"
          // color="success"
          // startIcon={<LockOpenIcon />}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteCampaign}
          >
            Delete
          </Button>
        </Box>
      </Modal1>



{/*     qr modal  */}
  <Modal1 isOpen={showQrModal}  onClose={()=> setShowQrModal(false)} maxWidth="800px" >
      <div ref={qrRef} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor:'pointer', padding:30 }}
            onClick={()=> {
              // setSelectedQr(campaignUrl)
              // setShowQrModal(true);
              // console.log(selectedQr)
              downloadQRCode();
            }}
          >
            <QRCode 
            // value={selectedCampaign}
            value={selectedQr}
              level={"Q"} size={500}

            />    
          </div>
          {/* <Button
          onClick={()=> setShowQrModal(false)}
          >
            Close
          </Button> */}
      </Modal1>

      <div className="campaign-header">
        <Header title="Campaigns" subtitle="List of Campaigns" />
        <span onClick={openModal}>
          <AddCircleOutlineIcon
            sx={{ fontSize: 50, cursor: "pointer", color: green[300] }}
          />
        </span>
      </div>

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #dddddd",
            fontSize: 14,
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            fontSize: 14,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={campaignData || mockDataInvoices}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          loading={isLoading}
          // getRowId={(row) => `${row.id} `}
        />
      </Box>
    </Box>
  );
};

export default Campaigns;
