import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import { apiSlice } from './slices/apiSlice';
import donorSlice from './slices/donorSlice';
import campaignSlice from './slices/campaignSlice';
import deviceSlice from './slices/deviceSlice';
import reportsSlice from './slices/reportsSlice';
import userAccountsSlice from './slices/userAccountsSlice';
import dashboardSlice from './slices/dashboardSlice';
import appLoadingSlice from './slices/appLoadingSlice';
import userSlice from './slices/userSlice';

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authSlice,
        user: userSlice,
        donor: donorSlice,
        campaign: campaignSlice,
        device: deviceSlice,
        report: reportsSlice,
        userAccount: userAccountsSlice,
        dashboard: dashboardSlice,
        appLoading : appLoadingSlice,
    },

    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
})