import {createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = {
    basicData: []
}



export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setBasicData: (state, action) => {
            state.basicData = action.payload.data
            console.log(state.basicData, 'dashboard basic data')
        },
       
    }
})

export const {setBasicData} = dashboardSlice.actions

export default dashboardSlice.reducer