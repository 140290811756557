import { apiSlice } from './apiSlice';

export const reportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.mutation({
      query: (data) => ({
        url: '/get-total-transactions/',
        method: 'POST',
        body: data
      }),
    }),
  }),
});

export const {
  useGetReportsMutation
} = reportApiSlice;
