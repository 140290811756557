import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataInvoices } from "../../data/mockData";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useGetDonorsMutation } from "../../state/slices/donorApiSlice";
import { useEffect } from "react";
import { setDonors } from "../../state/slices/donorSlice";



const Donors = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();

  const donorData = useSelector((state) => state.donor.donors);
  const [getDonors, {data, error, isLoading}] = useGetDonorsMutation()


  useEffect(() => {
    fetchDonors();
    
  }, []);

  const fetchDonors = async () =>{
    try {
      const donors = await getDonors();
      console.log(donors)
      dispatch(setDonors(donors));
    } catch (error) {
      console.log(error)
    }
  }


  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      // cellClassName: "name-column--cell",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      // cellClassName: "name-column--cell",
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "postcode",
      headerName: "Post Code",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "amount_in_pounds",
      headerName: "Amount in pounds",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "subscribe",
      headerName: "Subscribed",
      flex: 1,
    },
    {
      field: "timestamp",
      headerName: "Time Stamp",
      flex: 1,
    },
   
  ];

  return (
    <Box m="20px">
      <Header title="Donors" subtitle="List of Donors" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #dddddd",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: 'white',
            fontSize: 14
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
        // checkboxSelection 
        loading={isLoading}
        rows={donorData} columns={columns} 
        components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Donors;
