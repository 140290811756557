import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tokens: localStorage.getItem('tokens')
    ? JSON.parse(localStorage.getItem('tokens'))
    : null,
  isAuthenticated: !!localStorage.getItem('tokens'),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const tokens = action.payload;
      localStorage.setItem('tokens', JSON.stringify(tokens));
      state.tokens = tokens;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.tokens = null;
      state.isAuthenticated = false;
      localStorage.removeItem('tokens');
    },
    register: (state) => {
      state.tokens = null;
      state.isAuthenticated = false;
      // localStorage.removeItem('tokens');
    },
  },
});

export const { setCredentials, logout, register } = authSlice.actions;

export default authSlice.reducer;
