import {createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = {
    donors: []
}



export const donorSlice = createSlice({
    name: 'donor',
    initialState,
    reducers: {
        setDonors: (state, action) => {
            state.donors = action.payload.data
        },
       
    }
})

export const {setDonors, removeDonor} = donorSlice.actions

export default donorSlice.reducer