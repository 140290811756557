import {createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = {
    reports: []
}



export const reportsSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setReports: (state, action) => {
            state.reports = action.payload.data
            console.log(state.reports, 'reports')
        },
       
    }
})

export const {setReports, removeReports} = reportsSlice.actions

export default reportsSlice.reducer