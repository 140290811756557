// import { colors } from '@mui/material';
import React from 'react';

import { tokens } from '../theme';

import { useTheme } from '@emotion/react';


const Modal1 = ({ isOpen, onClose, maxWidth = '500px', children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(10px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      opacity: 0,
      visibility: 'hidden',
      transition: 'opacity 0.3s ease, visibility 0.3s ease',
    },
    overlayVisible: {
      opacity: 1,
      visibility: 'visible',
    },
    overlayHidden: {
      opacity: 0,
      visibility: 'hidden',
    },
    modal: {
      backgroundColor:  colors.primary[400],
      borderRadius: '8px',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      width: '100%',
      position: 'relative',
      transform: 'translateY(-50px)',
      opacity: 0,
      transition: 'transform 0.3s ease, opacity 0.3s ease',
    },
    modalVisible: {
      transform: 'translateY(0)',
      opacity: 1,
    },
    modalHidden: {
      transform: 'translateY(-50px)',
      opacity: 0,
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'transparent',
      border: 'none',
      fontSize: '16px',
      cursor: 'pointer',
    },
  };


  return (
    <div style={{ ...styles.overlay, ...(isOpen ? styles.overlayVisible : styles.overlayHidden) }}>
      <div style={{ ...styles.modal, ...(isOpen ? styles.modalVisible : styles.modalHidden), maxWidth }}>
        <button style={styles.closeButton} onClick={onClose}>X</button>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};



export default Modal1;
