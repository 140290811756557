import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataInvoices } from "../../data/mockData";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { useGetUserAccountsMutation, useApproveUserMutation } from "../../state/slices/userAccountsApiSlice";

import { setUserAccounts } from "../../state/slices/userAccountsSlice";

import toaster from '../../utils/toast'

const UserAccounts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();

  const userAccountsData = useSelector(
    (state) => state.userAccount.userAccounts
  );
  const approvedUsersData = useSelector(
    (state) => state.userAccount.approvedUsers
  );
  const [getUserAccounts, { data, error, isLoading }] =
    useGetUserAccountsMutation();


    const [approveUser, {approve_user_data, approve_user_error, approve_user_isLoading}] = useApproveUserMutation();

  useEffect(() => {
    fetchUserAccounts();
  }, []);

  const fetchUserAccounts = async () => {
    try {
      const user_accounts = await getUserAccounts();
      console.log(user_accounts);
      dispatch(setUserAccounts(user_accounts));
    } catch (error) {
      console.log(error);
    }
  };

  const handleApprove = async (user) => {

    const response =  await approveUser({id:user.id, active: user.is_active})
    console.log(response.data, 'response from main')

    if (response.data.user_approved){
      toaster('success', 'User approved')
      fetchUserAccounts();
    }else{
      toaster('error', 'error')

    }



  };

  const handleReject = (id) => {
    console.log(id);
  };

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      // cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      // cellClassName: "name-column--cell",
    },
    {
      field: "country_for_tax_purpose",
      headerName: "Country",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "telephone",
      headerName: "Telephone",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "organisation_name",
      headerName: "Organisation",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      // field: "entry_mode",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleApprove(params.row)}
            style={{ marginRight: 8 }}
          >
            Approve
          </button>
          <button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleReject(params.row.id)}
          >
            Reject
          </button>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="User Accounts" subtitle="" />
      {/* 
      <Box boxShadow={2} padding={2}>
        testing
      </Box> */}

      <h2> Pending Approvals</h2>
      <Box
        m="5px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #dddddd",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: "white",
            fontSize: 14,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          // checkboxSelection
          loading={isLoading}
          rows={userAccountsData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      {/*  ///////////*/}

      <h2 style={{ marginTop: 50 }}> Appvoved Accounts</h2>
      <Box
        m="5px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #dddddd",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: "white",
            fontSize: 14,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          // checkboxSelection
          loading={isLoading}
          rows={approvedUsersData}
          columns={columns.filter((column) => column.headerName !== "Actions")}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>

      {/* //////////// */}
    </Box>
  );
};

export default UserAccounts;
