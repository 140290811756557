import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from '../../utils/token';

const baseQuery = fetchBaseQuery({
  // baseUrl: 'http://127.0.0.1:8000/apiv2/',
  baseUrl: 'https://olivepod.co.uk/apiv2/',
  prepareHeaders: (headers) => {
    const token = getAccessToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery,
  endpoints: (builder) => ({}),
});
