import React from "react";
import Chart from "react-apexcharts";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const LineChart = ({ isDashboard = false, data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Ensure data is an array
  if (!Array.isArray(data)) {
    console.error("Data is not an array:", data);
    return null;
  }

  // Convert mock data to the format required by ApexCharts
  const series = data.map((line) => ({
    name: line.id,
    data: line.data?.map((point) => point.y),
  }));

  const options = {
    chart: {
      id: "basic-line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    xaxis: {
      categories: data[0]?.data?.map((point) => point.x) || [],
      labels: {
        style: {
          colors: colors.grey[100],
        },
      },
      axisBorder: {
        show: true,
        color: colors.grey[100],
      },
      axisTicks: {
        show: true,
        color: colors.grey[100],
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: colors.grey[100],
        },
      },
      title: {
        text: isDashboard ? undefined : "count",
        style: {
          color: colors.grey[100],
        },
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: true,
      labels: {
        colors: colors.grey[100],
      },
    },
    tooltip: {
      theme: "dark",
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 1,
      colors: ["#FFFFFF"],
      strokeColors: isDashboard ? ["#000000"] : ["#FFFFFF"],
      strokeWidth: 1,
    },
    colors: isDashboard ? data.map((line) => line.color) : ["#F44336", "#E91E63", "#9C27B0"],
  };

  return (
    <div>
      <Chart options={options} series={series} type="line" height="400" />
    </div>
  );
};

export default LineChart;
