import * as Yup from "yup";

export const loginSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email address"),
  password: Yup.string().min(3).required("Please enter your password"),
});

export const registerSchema = Yup.object({
  first_name: Yup.string().required("Please enter your first name"),
  last_name: Yup.string().required("Please enter your last name"),
  organisation_name: Yup.string().required("Please enter the organisation name"),
  // organisationnumber: Yup.string().required(
  //   "Please enter the organisation number"
  // ),
  // country: Yup.string().required("Please select a country"),
  address: Yup.string().required("Please enter your address"),
  telephone: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must be numeric")
    .min(10, "Phone number must be at least 10 digits long")
    .max(15, "Phone number must not exceed 15 digits")
    .required("Please enter your phone number"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Please create a password"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
  no_of_devices: Yup.number().when("packages", {
    is: "1", // Only when the package is 'Per Device Licence'
    then: Yup.number()
      .required("Please enter the number of devices")
      .min(1, "At least one device is required")
      .max(10, "No more than 10 devices are allowed"),
  }),
  packages: Yup.string().required("Please select a package"),
  subpackage: Yup.string().when("packages", {
    is: "2", // Only when the package is 'Unlimited Licence (Unlimited Devices)'
    then: Yup.string().required("Please select a sub-package option"),
  }),
  // Consider adding validations for document uploads if needed
}).required();


export const campaignSchema = Yup.object({
  campaign_type: Yup.string().required('Campaign type is required'),
  campaign_name: Yup.string().required('Campaign name is required'),
  multiple_campaign_names: Yup.array().when('campaign_type', {
    is: (val) => val !== 'single',
    then: Yup.array().of(Yup.string().required('Name is required')),
    otherwise: Yup.array().notRequired()
  }),
  template_name: Yup.string().required('Layout is required'),
  background_colour: Yup.string().required('Color is required'),
  text_colour: Yup.string().required('Color is required'),
  heading: Yup.string().required('Campaign heading is required'),
  message: Yup.string().required('Campaign message is required'),
  buttons: Yup.array().of(Yup.string().required('Amount is required')),
  custom_amount: Yup.boolean(),
  transaction_fee: Yup.boolean(),
  gift_aid: Yup.boolean(),
  subscribe: Yup.boolean(),
  custom_thanks: Yup.boolean(),
  thanks_message: Yup.string().when('thankYouMessage', {
    is: true,
    then: Yup.string().required('Custom thank you message is required')
  }),
});
