import {createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    is_loading: false
}


export const appLoadingSlice = createSlice({
    name: 'appLoading',
    initialState,
    reducers: {
        setLoading: (state, action) => {

            state.is_loading = action.payload
            console.log(action.payload)

            // axios.post()

        }
    }
})

export const {setLoading} = appLoadingSlice.actions

export default appLoadingSlice.reducer