import { Box, Button, Input, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUpdateFeeMutation, useUpdatePincodeMutation } from "../../state/slices/settingsApiSlice";
import { useGetUserMutation } from "../../state/slices/usersApiSlice";
import toaster from "../../utils/toast";

const Settings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const current_user = useSelector((state) => state.user.user);

  const [pincode, setPincode] = useState(current_user?.pin_code);
  const [fees, setFees] = useState(current_user?.transaction_fee);

  const [updatePincode] = useUpdatePincodeMutation();
  const [updateFee] = useUpdateFeeMutation();
  const [getUser] = useGetUserMutation();

  const handleUpdatePincode = async () => {
    const pincode_response = await updatePincode({ pincode }).unwrap();
    console.log(pincode_response);
    if (pincode_response.status) {
      toaster('success', 'Pin Code Updated');
      await getUser();
    } else {
      toaster('error', 'Something went wrong');
    }
  };

  const handleUpdateFees = async () => {
    const fee_response = await updateFee({ fees }).unwrap();  // Correcting this to use updateFee
    console.log(fee_response);

    if (fee_response.status) {
      toaster('success', 'Fees Updated');  // Correcting the success message
      // await getUser();
    } else {
      toaster('error', 'Something went wrong');
    }
  };

  return (
    <Box m="20px">
      <Header title="Settings" subtitle="" />
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 6"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginBottom={2}
          gap={2}
        >
          <Typography variant="h3">Update Pin Code</Typography>
          <Input
            type="number"
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 4) {
                setPincode(value);
              }
            }}
            value={pincode}
            inputProps={{ maxLength: 4, min: 1000, max: 9999 }}  // Setting maxLength to 4
          />
          <Button
            sx={{ backgroundColor: "#aaffaa" }}
            onClick={handleUpdatePincode}
          >
            Update
          </Button>
        </Box>
        <Box
          gridColumn="span 6"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginBottom={2}
          gap={2}
        >
          <Typography variant="h3">Fees:</Typography>
          <Input
            type="number"
            onChange={(e) => {
              setFees(e.target.value);
            }}
            value={fees}
            inputProps={{ min: 0, max: 100, step: 0.01 }}  // Setting min, max, and step values for the fees
          />
          <Button
            sx={{ backgroundColor: "#aaffaa" }}
            onClick={handleUpdateFees}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
