import React, { useState } from "react";
import Chart from "react-apexcharts";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const BarChart = ({ data, isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [hoveredBar, setHoveredBar] = useState(null);

  const customColors = [
    "#e6194b", "#3cb44b", "#ffe119", "#0082c8", "#f58231", "#911eb4",
    "#46f0f0", "#f032e6", "#d2f53c", "#fabebe", "#008080", "#e6beff",
    "#aa6e28", "#fffac8", "#800000", "#aaffc3", "#808000", "#ffd8b1",
    "#000080", "#808080", "#FFFFFF", "#000000"
  ];

  // Convert data to the format required by ApexCharts
  const series = [
    {
      name: "Total Donations",
      data: data.map((item) => item.value),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      events: {
        dataPointMouseEnter: (event, chartContext, config) => {
          setHoveredBar(config.dataPointIndex);
        },
        dataPointMouseLeave: () => {
          setHoveredBar(null);
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data.map((item) => item.product_summary),
      labels: {
        style: {
          colors: colors.grey[100],
        },
      },
      axisBorder: {
        color: colors.grey[100],
      },
      axisTicks: {
        color: colors.grey[100],
      },
    },
    yaxis: {
      title: {
        text: isDashboard ? undefined : "Total Donations",
        style: {
          color: colors.grey[100],
        },
      },
      labels: {
        style: {
          colors: colors.grey[100],
        },
      },
    },
    fill: {
      colors: customColors.map((color, index) => 
        index === hoveredBar ? color : color
      ),
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (value) => `${value}`,
      },
    },
    legend: {
      position: "bottom",
      labels: {
        colors: colors.grey[100],
      },
    },
    grid: {
      borderColor: colors.grey[100],
    },
  };

  return (
    <div>
      <Chart options={options} series={series} type="bar" height="470" />
    </div>
  );
};

export default BarChart;
