import {createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = {
    campaigns: []
}



export const campaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        setCampaign: (state, action) => {
            state.campaigns = action.payload.data
        },
       
    }
})

export const {setCampaign} = campaignSlice.actions

export default campaignSlice.reducer