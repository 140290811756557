import { apiSlice } from './apiSlice';

export const donorApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDonors: builder.mutation({
      query: (data) => ({
        url: '/get-donors/',
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetDonorsMutation
} = donorApiSlice;
