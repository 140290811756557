import { useDispatch, useSelector } from 'react-redux';
import { useGetReportsMutation } from '../state/slices/reportsApiSlice';
import { setReports } from '../state/slices/reportsSlice';

import { setUser } from '../state/slices/userSlice';
import { useGetUserMutation } from '../state/slices/usersApiSlice';

export const usePrefetchReports = () => {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.report.reports);
  const [getReports, { data, error,  }] = useGetReportsMutation();

  const [getUser, {isLoading}] = useGetUserMutation();


  const fetchReports = async () => {
    try {
      const reports = await getReports({ days: 365 }).unwrap();
      console.log(reports);
      dispatch(setReports(reports));
      const user_res = await getUser().unwrap()
      dispatch(setUser({user_res}))
    } catch (error) {
      console.error(error);
    }
  };

  return { fetchReports, reportData, isLoading, error };
};