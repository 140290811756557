import {createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = {
    userAccounts: [],
    approvedUsers: [],

}



export const userAccounts = createSlice({
    name: 'userAccount',
    initialState,
    reducers: {
        setUserAccounts: (state, action) => {
            state.userAccounts = action.payload.data.users
            state.approvedUsers = action.payload.data.aprooved_users
            console.log(action.payload.data , 'userAccounts')
        },
       
    }
})

export const {setUserAccounts} = userAccounts.actions

export default userAccounts.reducer