import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Typography, Box } from "@mui/material";
import toaster from "../../utils/toast";
import { Link, useNavigate } from "react-router-dom";
import './style.scss'
import olivepod2 from '../../images/olivepod2.webp'
import { useFormik } from "formik";
import { loginSchema } from "../../utils/form_schemas";
import {useLoginMutation, useTestMutation, useGetUserMutation} from '../../state/slices/usersApiSlice'
import { setCredentials } from "../../state/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../state/slices/userSlice";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     // Implement your login logic here
//     if (email !== "" && password !== "") {
//       toaster("success", " good nicce");
//     } else {
//       toaster("error", " please provide valid");
//     }
//   };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, {data, error, isLoading}] = useLoginMutation();
  const [test, {data2, error2, isLoading2}] = useTestMutation();
  const [getUser] = useGetUserMutation();



  const {isAuthenticated} = useSelector(state => state.auth)


 
  useEffect(() => {
    if (isAuthenticated) {
      // navigate('/');
      window.location.href = '/';

      // window.location.reload()
    }
  }, [isAuthenticated, navigate]);

  const handleTest = async () => {
    try {
      const res = await test().unwrap()
      console.log(res, '<------------ this is reponsoe test')
    } catch (error) {
      console.log(error)
    }
  }
  

  const submitHandler = async (req, res) => {
    try {
      const data = {email: values.email, password: values.password}
      const response = await login(data).unwrap()            
      dispatch(setCredentials({response}))
      // window.location.reload();

      const user_response = await getUser().unwrap();
      console.log(user_response, '<---user response')
      dispatch(setUser({user_response}))
      console.log('dispatched..')
      window.location.href = '/';
      // window.location.reload();
      // navigate('/')
      // console.log()

    } catch (error) {
        console.log(error)
    }
    
  }


  const  initialValues = {
    email: '',
    password: ''
  }
  const {values, errors, touched, handleBlur, handleChange, handleSubmit} =  useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: submitHandler

  })


  

////////////////=======================================================================///////////////////////////////////////////////////
  return (
    <div className="login-container" >
      <Box bgcolor="red" className="gradient-box" sx={{flexBasis:'30%', display:'flex', justifyContent:'center', alignItems:'center'}} >

            <img src={olivepod2} height="70px" />
        
      </Box>
      <Container component="main" maxWidth="xs" sx={{flexBasis:'70%', display:'flex', justifyContent:'center', alignItems:'center'}}  >
        <Box
          sx={{
            // marginTop: -10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h4">
            Please Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors?.email && touched?.email ? errors.email : null}
              error={errors.email && touched.email ? true : false}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={errors?.password && touched?.password ? errors.password : null}
              error={errors.password && touched.password ? true : false}
            />
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Link to="/register">
                Did not signup yet?
              </Link>
            </Box>

            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontSize: 14 }}
            >
              Sign In
            </Button>
          </Box>
          {/* <p onClick={handleTest} >TEst</p> */}
        </Box>
    
      </Container>
    </div>
  );
};

export default Login;
